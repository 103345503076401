import { Controller } from "stimulus"
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = {
    monthlyDebt: Number,
    monthlyIncome: Number,
    monthlyFixedExpenses: Number,
    monthlyVariableExpenses: Number,
    monthlyCashflow: Number,
    monthlyDebtInterest: Number,
    monthlyDebtPrincipal: Number,
  }
  static targets = [ "affiliateUrl", 'clickConfirmation', 'debtPlan', 'moneyPie' ]

  debtPlanTargetConnected() {
    this.buildDebtPlanDoughnut()
  }

  moneyPieTargetConnected() {
    this.buildMoneyPie()
  }

  copyUrlToClipboard() {
    console.log(this.clickConfirmationTarget.innerHTML, 'value')
    const confirmationTarget = this.clickConfirmationTarget

    navigator.clipboard.writeText(this.affiliateUrlTarget.innerHTML).then(() => {
      confirmationTarget.classList.remove('hidden')
      setTimeout(function(){
        confirmationTarget.classList.add("hidden");
      }, 2000);
    })
  }

  buildDebtPlanDoughnut() {
    const canvas = document.getElementById("dti-chart");
    const ctx = canvas.getContext("2d");

    const dtiValue = Math.round((this.monthlyDebtValue / this.monthlyIncomeValue) * 100)

    let dtiText;
    let chartColor;

    if (dtiValue < 35) {
      dtiText = "HEALTHY"
      chartColor = "rgb(70, 124, 34)" // green
    } else if (dtiValue <= 50) {
      dtiText = "NEEDS WORK"
      chartColor = "rgb(233, 248, 4)" // yellow
    } else {
      dtiText = "UNHEALTHY"
      chartColor = "rgb(254, 0, 0)" // red
    }

    const data = {
      labels: [],
      datasets: [
        {
          data: [dtiValue, 100 - dtiValue],
          backgroundColor: [
            chartColor, // debt
            'rgb(222, 222, 223)', // grey income
          ]
        }
      ]
    }

    const donutLabel = {
      id: 'donutLabel',
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, data } = chart;

        ctx.save();
        const xCoord = chart.getDatasetMeta(0).data[0].x
        const yCoord = chart.getDatasetMeta(0).data[0].y
        ctx.font = 'bold 35px Poppins'
        ctx.fillStyle = 'rgba(0, 0, 0)'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'bottom'
        ctx.fillText(`${dtiValue}%`, xCoord, yCoord)

        ctx.font = '20px Poppins'
        ctx.fillStyle = 'rgba(0, 0, 0)'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'bottom'
        ctx.fillText(dtiText, xCoord, yCoord + 30)
      }
    }

    new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: {
        cutout: '60%',
        plugins: {
          title: {
            display: true,
            text: 'Debt to Income Ratio',
            position: 'bottom',
            font: {
              size: 18,
            }
          }
        }
      },
      plugins: [donutLabel]
    })
  }

  buildMoneyPie() {
    const canvas = document.getElementById("money-pie");
    const ctx = canvas.getContext("2d");

    const data = {
      labels: ['Fixed Expenses', 'Variable Expenses', 'Cash Flow', 'Debt Interest', 'Debt Principal'],
      datasets: [
        {
          data: [
            Math.round(this.monthlyFixedExpensesValue),
            Math.round(this.monthlyVariableExpensesValue),
            Math.round(this.monthlyCashflowValue),
            Math.round(this.monthlyDebtInterestValue),
            Math.round(this.monthlyDebtPrincipalValue)
          ],
          backgroundColor: [
            'rgb(222, 222, 223)', // gray - fixed expenses 
            'rgb(0,0,0)', // black - variable expenses
            'rgb(70, 124, 34)', // green - cashflow
            'rgb(254, 0, 0)', // red - debt interest
            'rgb(73, 34, 134', // purple - debt principal
          ],
        },
      ],
    }
    const roundedMonthlyCashflow = Math.round(this.monthlyCashflowValue)

    const moneyPieLabel = {
      id: 'moneyPieLabel',
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, data } = chart;

        ctx.save();
        const xCoord = chart.getDatasetMeta(0).data[0].x
        const yCoord = chart.getDatasetMeta(0).data[0].y

        ctx.font = 'bold 35px Poppins'
        ctx.fillStyle = 'rgb(70, 124, 34)' // green
        ctx.textAlign = 'center'
        ctx.textBaseline = 'bottom'
        ctx.fillText('+', xCoord, yCoord)

        ctx.font = 'bold 35px Poppins'
        ctx.fillStyle = 'rgba(0, 0, 0)' // black
        ctx.textAlign = 'center'
        ctx.textBaseline = 'bottom'
        ctx.fillText(`$${roundedMonthlyCashflow}`, xCoord, yCoord + 30)

        ctx.font = '20px Poppins'
        ctx.fillStyle = 'rgb(70, 124, 34)' // green
        ctx.textAlign = 'center'
        ctx.textBaseline = 'bottom'
        ctx.fillText('cf', xCoord, yCoord + 600)
      }
    }

    new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: {
        layout: {
          padding: {
            top: 20,
            bottom: 20,
            left: 100,
            right: 150
          }
        },
        cutout: '40%',
        plugins: {
        },
        datasets: {
          doughnut: {
            borderWidth: 2,
            borderColor: 'white',
            hoverBorderWidth: 2
          }
        }
      },
      plugins: [moneyPieLabel]
    })
  }
}
